import { PrinterOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Image, Row, Space, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import nocover from "../../media/no-cover.png";
import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import { useSession } from "../../utils/Session";
import { parseAuthorsPlain, parseSubjectsPlain } from "../../utils/Utils";

export default function ListPrint(props) {
    const [session, setSession] = useSession(); 
    const { list_id = "" } = useParams();
    const [results, setResults] = useState({rows: []});
    const [list, setList] = useState({name : "", isbns_count : 0, owner_name : ""})
    const [error, setError] = useState(false);

    const getList = () => {
        document.title = "Print Preview";
        apiCall("titlelist/getItems", { list_id: list_id, limit: 500 }, (_status, _result) => {
            if (_status) {
                setResults(_result)
            } else {
                setError(_result.error);
            }
        })
        apiCall("titlelist/get", { list_id: list_id }, (_status, _result) => {
            if (_status) {
                setList(_result);
            }
        })
    }

    useEffect(getList, []);

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const drawRow = (_item) => {
        const {
            title = "",
            authors = [],
            isbn = "",
            eisbn = "",
            binding = "",
            release_date = "",
            series = "",
            subjects = [],
            price = ""
        } = _item;
        return (
            <div style={{ "width": "100%", "height": "160px" }}>
                <Row gutter={15} key={isbn}>
                    <Col flex={"95px"}>
                        <Space direction="vertical">
                            <img className="shadow-large" style={{ "maxWidth": "95px" }} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/m?b=" + eisbn} />
                        </Space>
                    </Col>
                    <Col flex={"80%"}>
                        <div style={{ "lineHeight": "14px" }}><strong>{title}</strong></div>
                        <div style={{ "marginTop": "-5px" }}><small>{parseAuthorsPlain(authors)}</small></div>
                        <div style={{ "marginTop": "-10px" }}><small><em>{binding}</em></small></div>
                        <div className="shim" />
                        {((series) && <div style={{ "marginTop": "-5px" }}><small><em>Series: </em>{series}</small></div>)}
                        <div style={{ "marginTop": "-5px", "lineHeight": "12px" }}><small>{parseSubjectsPlain(subjects)}</small></div>
                        <div className="shim" />
                        <div style={{ "marginTop": "-5px" }}><small><em>ISBN: </em>{isbn}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small><em>Published: </em>{moment(release_date * 1000).format("MMM Do, YYYY")}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small><strong>${price}</strong></small></div>
                    </Col>
                </Row>
                <Divider dashed />
            </div>
        )
    }

    if (error) {
        return (<div style={{ "margin": "0 auto", "width": "350px", "marginTop": "calc(20vh)" }}><Alert type="error" description={error} /></div>)
    }

    const printPage = () => {
        let printContents = document.getElementById('content').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }
    
    return (<>
        <div id="content">
            <div id="print" style={{ "padding": "0px 20px" }}>
                <div style={{ "height": "250px", "overflow": "hidden" }}>
                    <div className="shim" style={{ "height": "20px" }} />
                    <div style={{ "float": "right", "padding": "20px" }}><Button className="no-print" onClick={() => printPage()} icon={<PrinterOutlined />} type="primary">Print</Button></div>
                    <Typography.Title level={2}>{session.store_name}</Typography.Title>
                    <div style={{ "marginTop": "-10px" }}>
                        <Image src="https://www.bookmanager.com/i/tbm/letterhead_logo.jpg"/>
                        <div><strong>{list.name}</strong> </div>
                        <div>{(list.owner_name && <>Managed by: {list.owner_name} {((list.isbns_count) && <> | <em>({Math.min(list.isbns_count, 500)} titles)</em></>)}</>)}</div>
                        {(results.purchase_deadline && <><div>Purchase deadline : <strong>{moment(results.purchase_deadline * 1000).format("MMM Do, YYYY")}</strong></div></>)}
                        <Html html={list.description} />
                        <div>&nbsp;</div>
                    </div>
                    <Divider />
                </div>
                <div className="grgrid">
                    {results.rows.map(item => { return drawRow(item) })}
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        </div>
    </>
    )
}