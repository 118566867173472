import { NotificationOutlined } from '@ant-design/icons';
import { CloseCircleFilled } from '@ant-design/icons';
import { Avatar, Badge, Popover, Space } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";

export default function Notifications() {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const [vis, setVis] = useState(false);

    moment.updateLocale('en', {
        relativeTime: {
            future: "+%s",
            past: "%s",
            s: "1s",
            m: "1m",
            mm: "%dm",
            h: "1h",
            hh: "%dh",
            d: "1d",
            dd: "%dd",
            M: "1mth",
            MM: "%dmths",
            y: "1y",
            yy: "%dy"
        }
    });

    // {
    //     "recipient_id": 101069,
    //     "id": 10808,
    //     "message": "",
    //     "message_type": "repshare_resyn",
    //     "misc": null,
    //     "visibility": 3,
    //     "datecreated": 1650385626,
    //     "datechanged": 1650385626,
    //     "user": {
    //         "id": 554904,
    //         "name": "Michael Hunter"
    //     },
    //     "corp": {
    //         "id": 195194,
    //         "name": "BMA Sales Marketing Demo"
    //     }
    // },

    // repshare_ack
    // rem_repshare_ack
    // repshare_syn
    // rem_repshare_syn
    // repshare_resyn
    // rem_repshare_resyn

    const markSeen = (_id) => {
        apiCall("notification/markSeen", { notification_id: _id }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
        })
    }

    const hideRow = (_id) => {
        apiCall("notification/hide", { notification_id: _id }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
        })
    }


    const linkOut = (_type, _name) => {

        if(_type === "repshare_syn" || _type === "repshare_resyn" || "rem_repshare_ack"){
            history.push({ pathname: "/purchasing/my-reps", state: _name });
        } else {
            history.push("/purchasing/my-reps")
        }
       
    }

    /**
    // rep has requested to rep store
    public const TYPE_INIT_REP_REQUEST = 'rep_syn';

    // remind the rep they requested to rep store
    public const TYPE_INIT_REP_REQUEST_REMINDER = 'rem_rep_syn';

    // rep has rerequested to rep store
    public const TYPE_INIT_REP_REREQUEST = 'rep_resyn';

    // remind the rep they rerequested to rep store
    public const TYPE_INIT_REP_REREQUEST_REMINDER = 'rem_rep_resyn';

    // store approves/denies initial rep connection
    public const TYPE_INIT_REP_ACKNOWLEDGE = 'rep_ack';

    // remind store they replied to rep request
    public const TYPE_INIT_REP_ACKNOWLEDGE_REMINDER = 'rem_rep_ack';

    // REP CONSTS FOR REPSHARE CONNECTION
    // rep has requested to rep store
    public const TYPE_REP_REQUEST = 'repshare_syn';

    // remind the rep they requested to rep store
    public const TYPE_REP_REQUEST_REMINDER = 'rem_repshare_syn';

    // rep has rerequested to rep store
    public const TYPE_REP_REREQUEST = 'repshare_resyn';

    // remind the rep they rerequested to rep store
    public const TYPE_REP_REREQUEST_REMINDER = 'rem_repshare_resyn';

    // store approves/denies rep
    public const TYPE_REP_ACKNOWLEDGE = 'repshare_ack';

    // remind store they replied to rep request
    public const TYPE_REP_ACKNOWLEDGE_REMINDER = 'rem_repshare_ack';
    
    rem_repshare_syn
    repshare_ack
    rep_ack
    rem_repshare_resyn    
    */

    const buildNotification = (_note) => {
        let ret;
        let color = "#ffffff";
        // eslint-disable-next-line default-case
        switch (_note.message_type) {
            //initial rep request from the store
            case "rep_ack":
                if (_note.visibility !== 3) { color = (_note.misc.approved) ? "#00aa5f" : "#aa0000"; }
                ret = <><strong>{_note.corp.name}</strong> has {(_note.misc.approved) ? <strong style={{ "color": color }}>accepted</strong> : <strong style={{ "color": color }}>declined</strong>} your rep request.</>
                break;
            case "rem_rep_syn":
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> a rep request.</>
                break;
            case "rem_rep_resyn":
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> another rep request.</>
                break;

            // data sharing request with the rep
            case "repshare_ack":
                if (_note.visibility !== 3) { 
                    color = (_note.misc.approved) ? "#00aa5f" : "#aa0000"; 
                }
                ret = <><strong>{_note.corp.name}</strong> has {(_note.misc.approved) ? <strong style={{ "color": color }}>accepted</strong> : <strong style={{ "color": color }}>declined</strong>} your data share request.</>
                break;
            case "rem_repshare_syn":
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> a data share request.</>
                break;
            case "rem_repshare_resyn":
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> another data share request.</>
                break;
        }
        if (_note.visibility === 3) {
            return <div onMouseOut={() => markSeen(_note.id)} style={{ "backgroundColor": "#f12a46", "fontSize": "12px", "color": "#fff", "padding": "4px 10px", "borderRadius": "3px" }}>
                <div style={{ "float": "right", "paddingLeft": "20px" }}>
                    <CloseCircleFilled onClick={() => hideRow(_note.id)} />
                </div>
                <Space>
                    <div onClick={() => linkOut(_note.message_type, _note.user.name)}>{ret}</div>
                    <div><small>{moment(_note.datechanged * 1000).fromNow()}</small></div>
                </Space>
            </div>
        }
        return <div style={{ "padding": "4px 10px", "fontSize": "12px", "borderBottom": "1px solid #f1f1f1", "cursor": "pointer" }} >
            <div style={{ "float": "right", "paddingLeft": "20px" }}>
                <CloseCircleFilled style={{ "color": "#AAA" }} onClick={() => hideRow(_note.id)} />
            </div>
            <Space>
                <div onClick={() =>linkOut(_note.message_type, _note.user.name)}>{ret}</div>
                <div><small style={{ "color": "#AAA" }}>{moment(_note.datechanged * 1000).fromNow()}</small></div>
            </Space>
        </div>;
    }

    const [results, setResults] = useState([]);

    const fetchNotifications = () => {
        apiCall("notification/getAll", {}, (_status, _result) => {
            if (_status) {
                setResults(_result)
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(fetchNotifications, 5 * 60 * 1000);
        })
    }

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        fetchNotifications();    
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [vis]);

    const updateRow = (_item) => {
        let _update = [...results];
        let _index = _update.findIndex(itm => itm.id === _item.id);
        _update[_index] = _item;
        setResults(_update);
    }

    const content = (
        <div style={{ "maxHeight": "300px", "overflow": "hidden", "overflow": "auto", "paddingRight": "15px" }}>
            <Space size={0} direction="vertical">
                {results.filter(i => i.visibility !== 0).map((item, index) => {
                    return (<div key={index}>{buildNotification(item)}</div>);
                })}
            </Space>
        </div>
    );

    const hasNotifications = (results.filter(i => i.visibility === 3).length > 0);

    return (
        <>
            <Popover overlayStyle={{ "paddingTop": "25px" }} onVisibleChange={(e) => setVis(e)} placement="bottomRight" title={"Notifications"} content={content} trigger="click">
                <conditional.true value={(hasNotifications)}>
                    <Badge offset={[-1, 5]} style={{ "boxShadow": "none" }} className="dotnoborder" color={"#f12a46"} >
                        <Avatar size="small" style={{ "backgroundColor": "rgb(255,255,255,0.2)", "cursor": "pointer" }} icon={<small><NotificationOutlined /></small>} />
                    </Badge>
                </conditional.true>
                <conditional.true value={(!hasNotifications)}>
                    <Avatar size="small" style={{ "backgroundColor": "rgb(255,255,255,0.2)", "cursor": "pointer" }} icon={<small><NotificationOutlined /></small>} />
                </conditional.true>
            </Popover>
        </>
    )
}