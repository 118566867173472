import { Button, Checkbox, Divider, Input, message, Modal, Space, Table, Typography } from "antd";
import React, { useState } from "react";

import Paginate from "../../components/Paginate";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { bmDate, writeSharedReadable } from "../../utils/Utils";
import Overlay from "../Overlay";
import ListCreateEdit from "./ListCreateEdit";
import ListQuickPick from "./ListQuickPick";

export default function ListCount(props) {

    const { 
        isbn = "",
        myCatalogues = [], 
        title="", 
        updateMyListsInParent = () => {},
        effectiveDates = [],
        suppliers = [],
        agencies = [],
        categories = [],
    } = props;
    const [result, setResult] = useState({rows : []})
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [catalogueResults, setCatalogueResults] = useState({rows: []})
    const [search, setSearch] = useState("");
    const [session, setSession] = useSession();
    const [searchFilters, setSearchFilters] = useState({ o: 0, l: 20, t: "", k: "", v: ""})
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);

    const linkOut = (_id) => {
        window.open("/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }

    const updateMyLists = (_record) => {
        let _my_catalogues = [...myCatalogues];
        if(!_my_catalogues.includes(_record)){
            _my_catalogues.push(_record);
        }else{
            _my_catalogues.splice(_my_catalogues.indexOf(_record), 1);
        }
        updateMyListsInParent(_my_catalogues);
    }

    const updateRow = (_record) => {
        let _res = {...result};
        let _find = _res.rows.findIndex(item => item.list_id === _record.list_id);
        if(_find > -1){
            _res.rows[_find].in_list = _record.in_list; 
        }
        setResult(_res);
    }

    const toggle = (_list_id, _isbn, _checked = true, highlight = false) => {
        if(_checked){
            apiCall("titlelist/addItemToList", {isbn : _isbn, list_id : _list_id, highlight: highlight}, (_status, _result) => {
                if(_status){
                    updateMyLists(_result.list_id);
                    updateRow(_result);
                } else {
                    message.error(_result.error);
                }
            })
        } else {
            apiCall("titlelist/removeItemFromList", {isbn : _isbn, list_id : _list_id}, (_status, _result) => {
                if(_status){
                    updateMyLists(_result.list_id);
                    updateRow(_result);
                } else {
                    message.error(_result.error);
                }
            })
        }
    }

    const openModal = () => {
        setVisible(true);
        fetchMyLists();
    }

    const fetchMyLists = (_change = false, _obj = searchFilters) => {
        setLoading(true);
        let obj = {
            isbn : isbn,
            o: _obj.o, 
            l: _obj.l,
        }
        if(_obj.hasOwnProperty("t")){
            obj.t = _obj.t; 
        }
        apiCall("titlelist/getMyCatalogues", obj, (_status, _result) => {
            if (_status) {
                setResult(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        })
    }

    const close = () => {
        setVisible(false);
        fetchMyLists();
    }

    const updatedCount = () => {
        return myCatalogues.length;
    }

    const hasQP = (_id) =>{
        return (session.quick_pick_lists.some(item => item.list_id === _id)) ? 1 : 0;       
    }

    function dryRun(){
        apiCall("titlelist/removeFromAllLists", {isbn: isbn, dry_run: true}, (_status, _result) => {
            if(_status){
                confirmRemoveAll(_result.lists.length)
            }
        })
    }

    function confirmRemoveAll(lists_count){
        Modal.confirm({
            title: "Are you sure?",
            content: "You will be removing " + title + " from " + lists_count + " catalogues. Are you sure you want to do this?",
            okText: "Confirm",
            onOk: () => {
                setLoading(true);
                apiCall("titlelist/removeFromAllLists", {isbn: isbn, dry_run: false}, (_status, _result) => {
                    if(_status){
                        message.success("Successfully removed " + title + " from all " + lists_count + " catalogues!");
                    }
                    setLoading(false)
                    updateMyListsInParent([]);
                })
            }
        })
    }

    const drawMyLists = () => {
        return (
            <Modal destroyOnClose={true} width="55%" onCancel={() => close(false)} footer={null} visible={visible}>
                <div style={{ "padding": "0px" }} >
                    <Typography.Title level={4}>
                        <div>{title}</div>
                    </Typography.Title>
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>My Catalogues ({(updatedCount())})</div>
                        <div className="shim"></div>
                        <Divider dashed style={{ "marginTop": "10px", "marginBottom": "5px" }} />
                    </Typography.Title>
                    <Space style={{"float" : "right"}}>
                        <div style={{ "paddingLeft": "5px"}}> 
                            <Button onClick={() => dryRun()} type="danger" size="small"><small>Remove From All </small> </Button>
                        </div>
                        <Button 
                            type="primary" 
                            size="small"><small>
                                <Overlay 
                                    width={900} 
                                    component={
                                        <ListCreateEdit
                                            create={true}
                                            suppliers={suppliers}
                                            agencies={agencies}
                                            categories={categories}
                                            effectiveDates={effectiveDates}
                                            results={catalogueResults} 
                                            setResults={setCatalogueResults} 
                                            data={props.data}
                                            fromQP={true}
                                            fetchMyLists={fetchMyLists}
                                            />}>
                                    Create a catalogue
                                </Overlay>
                            </small>
                        </Button>
                    </Space>
                    <Input.Search 
                        onBlur={(e) => {
                            if(e.target.value === ""){
                                fetchMyLists("");
                            }
                        }} 
                        // onSearch={(_search) => fetchMyLists(_search)} 
                        onSearch={(e) => {
                            setCurrent(1);
                            let sf = { ...searchFilters, "t": e, "o": 0 }
                            setSearchFilters(sf);
                            fetchMyLists(false, sf);
                        }} 
                        onChange={(e) => setSearch(e.target.value)} 
                        placeholder="Search for a specific list..." 
                        style={{"width" : "60%"}} 
                        size="small"
                        enterButton />
                    <div className="shim" />
                    <Paginate 
                        loading={loading} 
                        paginate={{ 
                            current: current, 
                            offset: (searchFilters).hasOwnProperty("o") ? searchFilters.o : 0, 
                            pagesize: (searchFilters).hasOwnProperty("l") ? searchFilters.l : 20 
                        }} 
                        setPaginate={(e) => {
                            setCurrent(e.current);
                            let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                            fetchMyLists(false, sf);
                        }} 
                        count={result.row_count} />
                    <Table 
                        className="bdr"
                        loading={loading}
                        rowKey="list_id"
                        size="small"
                        pagination={false}
                        columns={columns}
                        scroll={{ y: 500 }}
                        dataSource={result.rows.sort((a, b) => hasQP(b.list_id) - hasQP(a.list_id))}
                    />
                    <br />
                    <div style={{ "float": "right" }}>
                        <Button onClick={() => close(false)}>Close</Button>
                    </div>
                    {/* <div><em>Showing 25 most recently updated lists. Use Search to find more.</em></div> */}
                    <br clear="all" />
                </div>
            </Modal>
        )
    }

    const columns = [
        {key : "blank", width : "10px"},
        { 
            title: <small><nobr>QP</nobr></small>, width: 40, dataIndex: 'quickpick', key: 'quickpick', render: (e, f) => {
                return <ListQuickPick list={f} list_id={f.list_id}/>
        }},
        {
            title: <small>Name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => linkOut(f.list_id)} >{e}</a> : "";
            }
        },
        {
            title: <small><nobr>Shared Status</nobr></small>, dataIndex: 'shared_status', key: 'shared_status', render: (e, f) => {
                return writeSharedReadable(f.shared_status);
            }
        },
        {
            title: <small>Season</small>, dataIndex: 'effective_string', key: 'effective_string', render: (e, f) => {
                return (f.effective_string) ?  f.effective_string : ""; 
            }
        },
        {
            title: <small>Category</small>, dataIndex: 'category', key: 'category', render: (e, f) => {
                return e; 
            }
        },
        {
            title: <small>Vendor</small>, dataIndex: 'shared_status', key: 'shared_status', render: (e, f) => {
                if(f.vendor){
                    let {name = ""} = f.vendor; 
                    return name; 
                }
                return ""
            }
        },
        { 
            title: <small>Titles</small>, width: 60, dataIndex: 'isbns_count', key: 'isbns_count' 
        },
        {
            title: <small><nobr>Last Updated</nobr></small>, width: 85, dataIndex: 'date_updated', key: 'date_updated', render: (e) => {
                return (e) ? <nobr>{bmDate(e * 1000)}</nobr> : ""
            }
        },
        { 
            title: <small><nobr>In Cat</nobr></small>, width: 50, dataIndex: 'vendor', key: 'vendor', render : (e, f) => {
                return (<div style={{"textAlign" : "center"}}><Checkbox onClick={(e) => toggle(f.list_id, isbn, e.target.checked)} checked={myCatalogues.includes(f.list_id)} disabled={f.is_dynamic}/></div>)
        }},
    ]

    return (
        <>
            {drawMyLists()}
            <Button onClick={() => openModal()} size="small" className="mini-btn" ><small>My catalogues ({(updatedCount())}) </small></Button>
        </>
    )
}