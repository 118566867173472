import { Button, Space, Table, Typography } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";

import { writeSharedReadable } from "../../utils/Utils";
import ListMSEmail from "./ListMultiSelectOptions/ListMSEmail";

export default function ListMultiSelect(props) {

    const { 
        catalogues = [],
        close = () => { }, 
        selectedRowKeys = [], 
    } = props;

    const [loading, setLoading] = useState(false);
    const [saveButtonText, setSaveButtonText] = useState("");
    const [apiCallText, setApiCallText] = useState();
    const [apiArgs, setApiArgs] = useState();

    const columns = [
        {
            title: "Catalogue Name", dataIndex: 'name', key: 'name'
        },
        {
            title: "Total Titles", dataIndex: 'isbns_count', key: 'isbns_count'
        },
        {
            title: "Shared Status", dataIndex: 'shared_status', key: 'shared_status', render: (e, f) => {
                return (
                    <div>
                        {writeSharedReadable(f.shared_status)}
                    </div>
                )
            }
        },
        {
            title: "Last Updated", dataIndex: 'date_updated', key: 'date_updated', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div>
                    </>
                )
            }
        }
    ];

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <div>
                    <Typography.Title level={5}>{selectedRowKeys.length} catalogues selected</Typography.Title>
                    <Table
                        size="small"
                        dataSource={catalogues}
                        columns={columns}>
                    </Table>
                    <ListMSEmail
                        setApiArgs={setApiArgs}
                        setApiCallText={setApiCallText}
                        selectedRowKeys={selectedRowKeys}
                        setSaveButtonText={setSaveButtonText}
                    />
                </div>
                <div style={{ "float": "left" }}>
                    <Space>
                        <Button type="danger" onClick={() => close()}>Cancel</Button>
                    </Space>
                </div>                
                <br clear="all" />
            </div>
        </>
    )
}