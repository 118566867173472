import { Checkbox, Col, Divider, Row, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { bmDate } from "../../utils/Utils";
import Paginate from "../Paginate";
import Title from "../title/Title";
import InvoiceActivity from "./InvoiceActivity";
import SupplierActivity from "./SupplierActivity";

export default function CustomerActivity(props) {

    const { customer_ship_to } = props;
    const [results, setResults] = useState({ customer_info: {}, total: 0, rows: [] });
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 10, offset: 0 });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [drawType, setDrawType] = useState("");
    const [more, setMore] = useState(false)

    const [filters, setFilters] = useState({
        ship_to: customer_ship_to,
        limit: 100,
        offset: 0,
        orders: false,
        returns: false,
        sales: false,
        recent: false
    });

    const convertFBPoints = (_int) => {
        let ret;
        if (isNaN(_int)) {
            ret = "N/A";
        } else {
            ret = (_int / 100).toFixed(2);
            ret = "$" + ret.toString();
        }
        return ret;
    }

    const getCustomerActivity = () => {
        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;
        apiCall("activity/getByShipto", _filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getCustomerActivity, [filters, paginate]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const columns = [
        {
            title: <small>Date</small>, dataIndex: "date", key: "date", render: (e) => {
                return (e) ? bmDate(e * 1000) : "";
            }
        },
        { title: <small>Status</small>, dataIndex: "status", key: "status" },
        { title: <small>Qty</small>, dataIndex: "quantity", key: "quantity" },
        { title: <small>Price</small>, dataIndex: "price", key: "price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        {
            title: <small>Title</small>, className: "nowrap", dataIndex: "title", key: "title", render: (e, f) => {
                return (<a onClick={() => { window.open("/browse/filter/t/" + f.isbn, "_blank") }}>{e}</a>)
            }
        },
        {
            title: <small>Supp</small>, dataIndex: "supplier", key: "supplier"
        },
        {
            title: <small>P/O</small>, dataIndex: "purchase_order", key: "purchase_order"
        },
        { title: <small>Cust PO</small>, dataIndex: "customer_purchase_order", key: "customer_purchase_order" },
        {
            title: <small>Invoice</small>, dataIndex: "invoice_display", key: "invoice_display"
        },
        { title: <small>Clrk</small>, dataIndex: "cashier", key: "cashier" }
    ]

    const drawFilters = () => {
        return (
            <div className="bc" style={{ "margin": "0px", "padding": "2px 5px", "borderTop": "1px solid", "borderBottom": "1px solid" }}>
                <Checkbox className="mini-checkbox" checked={filters.recent} onChange={(e) => setFilters({ ...filters, "offset": 0, "recent": e.target.checked })} />
                <small> Show last 60 days only</small>
                <div style={{ "float": "right" }}>
                    <Space size={7}>
                        <div>
                            <Checkbox className="mini-checkbox" checked={(!filters.orders && !filters.returns && !filters.sales)} onChange={(e) => setFilters({ ...filters, "offset": 0, "orders": false, "returns": false, "sales": false })} />
                            <small> All</small>
                        </div>
                        <div>
                            <Checkbox className="mini-checkbox" checked={filters.orders} onChange={(e) => setFilters({ ...filters, "offset": 0, "orders": e.target.checked })} />
                            <small> Orders</small>
                        </div>
                        <div>
                            <Checkbox className="mini-checkbox" checked={filters.returns} onChange={(e) => setFilters({ ...filters, "offset": 0, "returns": e.target.checked })} />
                            <small> Returns</small>
                        </div>
                        <div>
                            <Checkbox className="mini-checkbox" checked={filters.sales} onChange={(e) => setFilters({ ...filters, "offset": 0, "sales": e.target.checked })} />
                            <small> Sales</small>
                        </div>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        )
    }

    const drawContact = (_info = {}) => {
        const {
            ship_to = "",
            created = null,
            last_activity = null,
            discount = null,
            frequent_buyer = false,
            frequent_buyer_points = 0,
            customer_type = "",
            name = "",
            attn = "",
            address_1 = "",
            address_2 = "",
            city = "",
            province = "",
            country = "",
            email = "",
            phone = "",
            phone_2 = "",
            fax = "",
            notes = "",
            // MORE DETAILS
            tax_code = '',
            tx1 = '',
            tx2 = '',
            sales_this = '',
            sales_last = '',
            sales_penultimate = '',
            combined_po = '',
            over_due = '',
            credit_limit = '',
        } = _info;

        return (
            <>
                <div style={{ "padding": "0px", "paddingBottom": "0px", "margin": "0px 0px" }}>
                    <Row>
                        <Col span={12}>
                            <div style={{ "padding": "0px 5px" }}>
                                <Typography.Paragraph style={{ "fontSize": "11px" }}>
                                    <conditional.true value={(name)}>
                                        <span className="c" style={{ "fontSize": "14px", "display": "inline" }}><strong>{name}</strong>
                                            &nbsp;<small style={{ "fontSize": "10px" }}>({ship_to})</small>
                                        </span>
                                        <div style={{ "height": "0px" }} className="shim"></div>
                                    </conditional.true>
                                    <conditional.true value={(email)}>
                                        <div style={{ "marginTop": "-2px" }}><a href={"mailto:" + email}>{email}</a></div>
                                        <div className="shim"></div>
                                    </conditional.true>
                                    <conditional.true value={(address_1 || address_2)}>
                                        {address_1} {address_2} <br />
                                    </conditional.true>
                                    <conditional.true value={(city)}>
                                        {city}, {province} {country} <br />
                                    </conditional.true>
                                    <conditional.true value={(phone || phone)}>
                                        <conditional.true value={(phone && phone_2)}>
                                            <conditional.true value={(phone)}><strong>{phone}</strong></conditional.true> -
                                            <conditional.true value={(phone_2)}><strong> {phone_2}</strong></conditional.true>
                                        </conditional.true>
                                        <conditional.true value={!(phone && phone_2)}>
                                            <conditional.true value={(phone)}><strong>{phone}</strong></conditional.true>
                                            <conditional.true value={(phone_2)}><strong> {phone_2}</strong></conditional.true>
                                        </conditional.true>
                                    </conditional.true>
                                    <conditional.true value={(more)}>
                                        <div className="shim" /><div className="shim" />
                                        <Space size={20} >
                                            <span style={{ "fontSize": "11px", "display": "block" }}>
                                                <div style={{ "display": "inline-block", "marginRight": "10px" }}>
                                                    <div style={{ "width": "75px", "textAlign": "left" }}>
                                                        <div><strong>Taxes: </strong></div>
                                                        <Space className="bc" style={{ "border": "1px solid", "padding": "10px" }} direction="vertical">
                                                            <div style={{ "width": "75px" }}><strong>Tx: </strong>{tax_code}</div>
                                                            <div><strong>Tx1: </strong>{tx1}</div>
                                                            <div><strong>Tx2: </strong>{tx2}</div>
                                                        </Space>
                                                    </div>
                                                </div>
                                            </span>
                                            <span style={{ "fontSize": "11px", "display": "block" }}>
                                                <div style={{ "display": "inline-block", "marginRight": "10px" }}>
                                                    <div style={{ "width": "75px", "textAlign": "left" }}>
                                                        <div><strong>Sales: </strong></div>
                                                        <Space className="bc" style={{ "border": "1px solid", "padding": "10px" }} direction="vertical">
                                                            <div style={{ "width": "75px" }}><strong>{moment().format("YYYY")}:  </strong>{sales_this}</div>
                                                            <div><strong>{moment().add(-1, "year").format("YYYY")}: </strong>{sales_penultimate}</div>
                                                            <div><strong>{moment().add(-2, "years").format("YYYY")}: </strong>{sales_last}</div>
                                                        </Space>
                                                    </div>
                                                </div>
                                            </span>
                                        </Space>
                                    </conditional.true>
                                </Typography.Paragraph>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{ "textAlign": "right", "padding": "8px" }}>
                                <Typography.Paragraph style={{ "fontSize": "12px" }}>
                                    <span style={{ "fontSize": "11px", "display": "block" }}><strong>Created: </strong>{bmDate(created * 1000)}</span>
                                    <span style={{ "fontSize": "11px", "display": "block" }}><strong>Last Activity: </strong>{bmDate(last_activity * 1000)}</span>
                                    <conditional.true value={(customer_type)}>
                                        <span style={{ "fontSize": "11px", "display": "block" }}><strong>Customer type: </strong>{customer_type}</span>
                                    </conditional.true>
                                    <conditional.true value={(attn)}>
                                        <span style={{ "fontSize": "11px", "display": "block" }}><strong>Attn: </strong>{attn}</span>
                                    </conditional.true>
                                    <conditional.true value={(discount)}>
                                        <span style={{ "color": "#228b22", "fontSize": "11px", "display": "block" }}><strong>Standing Discount: </strong>{discount}% </span>
                                    </conditional.true>
                                    <span style={{ "fontSize": "11px", "display": "block" }}><strong>FB Points: </strong>{convertFBPoints(frequent_buyer_points)}</span>
                                    <conditional.true value={(more)}>
                                        <conditional.true value={(fax !== "")}><span style={{ "fontSize": "11px", "display": "block" }}><strong>Fax : </strong>{fax}</span></conditional.true>
                                        <conditional.true value={(combined_po !== "")}><span style={{ "fontSize": "11px", "display": "block" }}><strong>Combined PO  : </strong>{combined_po}</span></conditional.true>
                                        <conditional.true value={(over_due !== "")}><span style={{ "fontSize": "11px", "display": "block" }}><strong>AR Ttl  : </strong>{over_due}</span></conditional.true>
                                        <conditional.true value={(credit_limit !== "")}><span style={{ "fontSize": "11px", "display": "block" }}><strong>Credit Limit  : </strong>{credit_limit}</span></conditional.true>
                                    </conditional.true>
                                </Typography.Paragraph>
                            </div>
                        </Col>
                    </Row>
                    <div onClick={() => setMore(!more)} style={{ "fontSize": "11px", "display": "block", "float": "right" }}><strong><a>{(!more) ? "More" : "Less"} account info &nbsp;&nbsp;</a></strong></div>
                    <div className="shim" />
                    <br clear="all" />
                </div>
            </>
        )
    }

    const drawChildTable = (_record) => {
        if (drawType === "title") {
            return <Title isbn={_record.isbn} />;
        }
        if (drawType === "invoice") {
            return <div className="hideHover" style={{ "marginLeft": "-8px", "marginRight": "-3px", "marginTop": "-4px", "marginBottom": "-4px" }}><InvoiceActivity close={setExpanded} invoice_is_supplier={_record.invoice_is_supplier} invoice={_record.invoice_key} /></div>
        }
        if (drawType === "supplier") {
            return <div className="hideHover" style={{ "marginLeft": "-8px", "marginRight": "-3px", "marginTop": "-4px", "marginBottom": "-4px" }}><SupplierActivity close={setExpanded} invoice_is_supplier={_record.invoice_is_supplier} supplier={_record.supplier} /></div>
        }
        return (<></>);
    }

    const expandCell = (_data, _type) => {
        setDrawType(_type);
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            if (_type === drawType) {
                setExpanded([]);
            } else {
                setExpanded([_data.key]);
            }
        }
    }

    if (loading) {
        return (<div style={{ "margin": "-8px" }}></div>);
    }

    return (
        <div style={{ "margin": "0px", "padding": "20px", "backgroundColor": "#fff" }}>
            <Typography.Title level={5}>Customer</Typography.Title>
            <Divider style={{ "margin": "15px 0px" }} />
            <>
                {drawContact(results.customer_info)}
                <div className="shim" /><div className="shim" />
                {drawFilters()}
                <div className="shim" /><div className="shim" />
            </>
            <div className="bc" style={{ "border": "1px solid" }}>
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
                <Table
                    size="small"
                    columns={columns}
                    className={"mediumTable"}
                    rowClassName={(record, index) => {
                        return "sales-" + record.status;
                    }}
                    dataSource={results.rows}
                    pagination={false}
                    expandable
                    expandedRowKeys={expanded}
                    expandIconColumnIndex={-1}
                    onExpand={(e) => { return false; }}
                    expandIcon={() => { return (<></>) }}
                    expandedRowRender={((record) => drawChildTable(record))}
                />
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
            </div>
        </div>
    )
}