import { Button, Checkbox, Radio, Space, Table, Tooltip, Typography } from "antd";
import { useState } from "react";

export default function PeerDataMarketSelector(props){
    const {
        locationGroups = [],
        setFilters = () => {},
        filters = {},
        close = () => {},
    } = props;

    const {
        locations = [],
    } = locationGroups

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [displayMenuOption, setDisplayMenuOption] = useState("market");

    function toggleMultiSelect(san, checked){
        let tempSelectedKeys = [...selectedRowKeys]
        if(checked){
            tempSelectedKeys.push(san);
        }else{ 
            tempSelectedKeys.splice(tempSelectedKeys.indexOf(san), 1);
        }
        setSelectedRowKeys(tempSelectedKeys);
    }

    const columns = [
        {
            title: <small>Select</small>, width: "25px", render : (e, f) => {
                return (<Checkbox onClick={(e) => toggleMultiSelect(f.san, e.target.checked)}/>)
            }
        },
        {
            title: <small>Name</small>, dataIndex: "name", key: "name",
        }
    ]

    const onRadioButtonSwitch = () => {
        switch (displayMenuOption) {
            case "market":
                return (
                    <div style={{"paddingBottom": "20px"}}>
                        <Radio.Group onChange={(e) => {setFilters({ ...filters, "location_group": e.target.value })}} value={filters.location_group}>
                            <Space direction="vertical">
                                <Radio value={"All"}>{"All Markets (" + locations.All.length + " stores)" }</Radio>
                                <Radio value={"Canada"}>{"Canadian Stores (" + locations.Canada.length + " stores)"}</Radio>
                                <Radio value={"Christian"}>{"Christian Only (" + locations.Christian.length + " stores)" }</Radio>
                                <Radio value={"My Stores"}>
                                    <Tooltip title="This shows you all your accounts across all markets, which is different from the checkbox titled Just My Accounts ">
                                        {"Only My Accounts (" + locations["My Stores"].length + " stores)" }
                                    </Tooltip> 
                                </Radio>
                                <Radio value={"USA"}>{"USA (" + locations.USA.length + " stores)" }</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                );
            case "stores":
                return(
                    <div style={{"paddingBottom": "20px"}}>
                        <Table
                            size="small"
                            columns={columns}
                            >
                            
                        </Table>
                    </div>
                )
            default:
                return (
                    <div>
                        You shouldn't be here, refresh the page maybe?
                    </div>
                )
        } 
    }

    return (
        <div style={{ "padding": "20px" }}>
            <Typography.Title level={5}>Multi-Select Menu</Typography.Title>
            <Typography.Paragraph>
                Due to the large sizes of data being accessed, the table can take a moment to refresh.
            </Typography.Paragraph>
            <Radio.Group 
                defaultValue={displayMenuOption} 
                buttonStyle="solid" 
                onChange={(e) => setDisplayMenuOption(e.target.value)}
                style={{"paddingBottom": "10px"}}>
                <Radio.Button value="market">By Market</Radio.Button>
                <Radio.Button value="stores">By Stores</Radio.Button>
            </Radio.Group>
            {onRadioButtonSwitch()}
            <span style={{ "float": "right" }}>
                <Space>
                    <Button onClick={(e) => close()}>Close</Button>
                    <Button type="primary" onClick={(e) => close()}>Save Changes</Button>
                </Space>
            </span>
            <br clear="all" />
        </div>
    )
}