import { Button, Divider, message, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { bmDate } from "../../utils/Utils";
import Paginate from "../Paginate";
import Title from "../title/Title";

export default function InvoiceActivity(props) {
    const { invoice, invoice_is_supplier = true, close = () => { } } = props;
    const [results, setResults] = useState({ header_info: { is_supplier: false }, total: 0, rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [drawType, setDrawType] = useState("")
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 10, offset: 0 });
    const [filters, setFilters] = useState({
        invoice: invoice,
        invoice_is_supplier: invoice_is_supplier,
        limit: 100,
        offset: 0,
    });

    const getCustomerActivity = () => {
        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;
        apiCall("activity/getByInvoice", _filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getCustomerActivity, [paginate]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const columns = [
        { title: <small>Status</small>, dataIndex: "status", key: "status" },
        { title: <small>Price</small>, dataIndex: "price", key: "price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        { title: <small>%</small>, dataIndex: "discount", key: "discount", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        { title: <small>Profit</small>, dataIndex: "profit_margin", key: "profit_margin", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        {
            title: <small>Title</small>, width: "200px", className: "nowrap", dataIndex: "title", key: "title", render: (e, f) => {
                return (<a onClick={() => { window.open("/browse/filter/t/" + f.isbn, "_blank") }}>{e}</a>)
            }
        },
        { title: <small>ISBN</small>, dataIndex: "isbn", key: "isbn" },
        { title: <small>Cust PO</small>, dataIndex: "customer_purchase_order", key: "customer_purchase_order" },
        { title: <small>PO</small>, dataIndex: "purchase_order", key: "purchase_order" },
    ]

    if (results.header_info.is_supplier) {
        // Supplier
        columns.unshift({ title: <small>Qty</small>, dataIndex: "quantity", key: "quantity" },)
        columns.push({ title: <small>Age</small>, dataIndex: "age", key: "age" })
        columns.push({ title: <small><nobr>Cancels In</nobr></small>, dataIndex: "cancel", key: "cancel" })
        columns.push({ title: <small>Clrk</small>, dataIndex: "cashier", key: "cashier" })
        columns.push({ title: <small>Note</small>, width: "200px", dataIndex: "note", key: "note" })
    } else {
        // !Supplier
        columns.push({ title: <small>Class</small>, dataIndex: "class", key: "class" })
        columns.push({ title: <small>On hand</small>, dataIndex: "onhand", key: "onhand" })
    }

    const [browseLoading, setBrowseLoading] = useState(false);

    const linkOut = (_id) => {
        window.open("/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }

    const viewInBrowse = () => {
        setBrowseLoading(true)
        apiCall("activity/getCacheKeyByInvoice", {invoice : invoice, invoice_is_supplier : invoice_is_supplier} , (_status, _result) => {
            if(_status){
                // link to browse in a new tab
                linkOut(_result.id)
            } else {
                message.error(_result.error);
            }
            setBrowseLoading(false)
        })
    }

    const drawHeader = (_info) => {
        if (!_info) {
            _info = {}
        }
        const {
            customer_name = "",
            is_supplier = false,
            supplier_code = "",
            invoice = "",
            date = null
        } = _info;
        return (
            <div>
                <div style={{"float" : "right"}}><Button loading={browseLoading} onClick={() => viewInBrowse()} size="small" type="primary"><small>Browse titles</small></Button></div>
                <div style={{ "padding": "0px 5px", "paddingBottom": "0px", "marginTop": "-5px" }}>
                    <Typography.Paragraph style={{ "fontSize": "12px" }}>
                        <conditional.true value={(invoice)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>Invoice: <strong>{invoice}</strong></span>
                        </conditional.true>
                        <conditional.true value={(supplier_code)}>
                            <span style={{ "fontSize": "11px", "display": "block" }}>Supplier:<strong> {supplier_code}</strong></span>
                        </conditional.true>
                        <conditional.true value={(date)}>
                            <span style={{ "fontSize": "11px", "display": "block" }}>Date: <strong>{bmDate(date * 1000)}</strong></span>
                        </conditional.true>
                        <conditional.true value={(customer_name)}>
                            <span style={{ "fontSize": "11px", "display": "block" }}>Customer: <strong>{customer_name}</strong></span>
                        </conditional.true>
                    </Typography.Paragraph>
                </div>
                
            </div>
        )
    }

    const drawChildTable = (_record) => {
        if (drawType === "title") {
            return <Title isbn={_record.isbn} />;
        }
        if (drawType === "purchase_order") {
            return (<></>);
        }
        return (<></>);
    }

    const expandCell = (_data, _type) => {
        setDrawType(_type);
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            if (_type === drawType) {
                setExpanded([]);
            } else {
                setExpanded([_data.key]);
            }
        }
    }

    return (
        <div style={{ "padding": "20px", "margin": "0px" }}>
            <Typography.Title level={5}>Invoice</Typography.Title>
            <Divider style={{ "margin": "15px 0px" }} />
            {drawHeader(results.header_info)}
            <div className="bc" style={{ "border": "1px solid" }}>
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
                <Table
                    size="small"
                    loading={loading}
                    columns={columns}
                    className={"tableNoBorder"}
                    dataSource={results.rows}
                    pagination={false}
                    rowClassName={(record, index) => {
                        return "sales-" + record.status;
                    }}
                    expandedRowKeys={expanded}
                    expandIconColumnIndex={-1}
                    onExpand={(e) => { return false; }}
                    expandIcon={() => { return (<></>) }}
                    expandedRowRender={((record) => drawChildTable(record))}
                />
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
            </div>
        </div>
    )
}