import { Button, Checkbox, Input, message, Modal, Radio, Select, Space, Table, Tabs, Tooltip, Typography } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import ListCreateEdit from "../../lists/ListCreateEdit"
import MultiSelectAddRemoveList from "./MultiSelectAddRemoveList";
import MultiSelectExcel from "./MultiSelectExcel";
import MultiSelectHighlightToggle from "./MultiSelectHighlightToggle";
import MultiSelectPDF from "./MultiSelectPDF";

export default function BrowseMultiSelect(props){
    
    const { 
        close = () => { }, 
        catalogueID = "",
        available_corps = [],
        selectedRowKeys = [],
        titles = [],
        suppliers = "",
        agencies = "",
        categories = "",
        setResults = () => {},
        setSelectedRowKeys = () => {},
        results="",
        effectiveDates = "",
        toggleSelectMultiple = () => {},
        resetSelection = () => {},
    } = props;

    const [position, setPosition] = useState("add");
    //states: add, remove, create_catalogue, highlight, unhighlight

    function getFullISBNData(list_edit_values){
        let fullIsbnData = selectedRowKeys.map((isbn, index) => {
            if(titles.find((title) => title.isbn === isbn)){
                return titles.find((title) => title.isbn === isbn)
            }
        })
        if(list_edit_values){
            return {
                "isbns_full_data": fullIsbnData,
                "selectedRowKeys": selectedRowKeys.map((isbn,index) => {
                    return {
                        "isbn": isbn,
                        "highlight": false
                    }
                })
            }
        }
        return {"isbns_full_data": fullIsbnData, "selectedRowKeys": selectedRowKeys};
    }

    function changeTabs(position) {
        setPosition(position);
    }

    return (
        <>
            <Tabs  
                className="itemTabs"  
                onChange={(e) => changeTabs(e)} 
                defaultActiveKey="0"
                activeKey={position} 
                type="card" 
            >
                <Tabs.TabPane tab="Add to Catalogue" key="add">
                    <MultiSelectAddRemoveList
                        isbns={selectedRowKeys}
                        available_corps={available_corps}
                        action="add"
                        titles={titles}
                        close={close}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Remove from Catalogue" key="remove">
                    <MultiSelectAddRemoveList
                        available_corps={available_corps}
                        catalogueID={catalogueID}
                        isbns={selectedRowKeys}
                        action="remove"
                        titles={titles}
                        close={close}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Create Catalogue with Titles" key="create_catalogue">
                    <ListCreateEdit 
                        create={true}
                        suppliers={suppliers}
                        agencies={agencies}
                        categories={categories}
                        effectiveDates={effectiveDates}
                        results={results} 
                        setResults={setResults}
                        close={close}
                        data={getFullISBNData(true)} 
                    />
                </Tabs.TabPane>
                {((catalogueID) && 
                    <Tabs.TabPane tab="Highlight" key="highlight">
                        <MultiSelectHighlightToggle
                            highlight={true}
                            results={results}
                            setResults={setResults}
                            catalogueID={catalogueID}
                            close={close}
                            data={getFullISBNData(false)}/>
                    </Tabs.TabPane>
                )}
                {((catalogueID) && 
                    <Tabs.TabPane tab="Unhighlight" key="unhighlight">
                        <MultiSelectHighlightToggle
                            highlight={false}
                            results={results}
                            setResults={setResults}
                            close={close}
                            catalogueID={catalogueID}
                            data={getFullISBNData(false)}/>
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="PDF" key="pdf">
                    <MultiSelectPDF 
                        isbns={selectedRowKeys} 
                        setSelectedRowKeys={setSelectedRowKeys}
                        close={close}
                    />     
                </Tabs.TabPane>
                <Tabs.TabPane tab="Excel" key="excel">
                    <MultiSelectExcel 
                        isbns={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        close={close}
                    /> 
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}