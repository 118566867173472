import { Checkbox, Divider, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { bmDate } from "../../utils/Utils";
import Paginate from "../Paginate";
import PurchaseActivity from "./PurchaseActivity";

export default function SupplierActivity(props) {
    const { supplier} = props;
    const [results, setResults] = useState({ customer_info: {}, total: 0, rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 10, offset: 0 });

    const initFilters = {
        supplier: supplier,
        limit: 100,
        offset: 0,
        orders: false,
        returns: false,
        recent: false
    }

    const [filters, setFilters] = useState(initFilters);
    
    const saveFilters = (_obj) => {
        setFilters(_obj);
    }

    const getCustomerActivity = () => {
        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;
        setLoading(true);
        apiCall("activity/getBySupplier", _filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
            }
            setLoading(false);
        })
    }

    useEffect(getCustomerActivity, [paginate, filters]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const columns = [
        {
            title: <small>Date</small>, dataIndex: "date", key: "date", render: (e) => {
                return (e) ? moment(e * 1000).format("MMM Do YYYY") : "";
            }
        },
        { title: <small>Status</small>, dataIndex: "status", key: "status" },
        {
            title: <small>Purchase Order</small>, dataIndex: "purchase_order", key: "purchase_order", render: (e, f) => {
                return (e) ? e : "";
            }
        },
        { title: <small>Items</small>, dataIndex: "items", key: "items" },
        { title: <small>Note</small>, className: "nowrap", dataIndex: "note", key: "note" }
    ]

    const drawFilters = () => {
        return (
            <div className="bc bcg2" style={{ "margin": "0px", "padding": "2px 5px", "borderTop": "1px solid", "borderBottom": "1px solid" }}>
                <Checkbox className="mini-checkbox" checked={filters.recent} onChange={(e) => saveFilters({ ...filters, "offset": 0, "recent": e.target.checked })} />
                <small> Show last 60 days only</small>
                <div style={{ "float": "right" }}>
                    <Space size={7}>
                        <div>
                            <Checkbox className="mini-checkbox" checked={(!filters.orders && !filters.returns)} onChange={(e) => saveFilters({ ...filters, "offset": 0, "orders": false, "returns": false })} />
                            <small> All</small>
                        </div>
                        <div>
                            <Checkbox className="mini-checkbox" checked={filters.orders} onChange={(e) => saveFilters({ ...filters, "offset": 0, "orders": e.target.checked })} />
                            <small> Orders</small>
                        </div>
                        <div>
                            <Checkbox className="mini-checkbox" checked={filters.returns} onChange={(e) => saveFilters({ ...filters, "offset": 0, "returns": e.target.checked })} />
                            <small> Returns</small>
                        </div>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        )
    }

    const drawContact = (_info = {}) => {
        const {
            supplier = "",
            created = null,
            name = "",
            address_1 = "",
            address_2 = "",
            city = "",
            province = "",
            country = "",
            email = "",
            phone = "",
            phone_2 = "",
            fax = "",
        } = _info;
        return (
            <>
                <div style={{ "padding": "0px 5px", "paddingBottom": "0px", "marginTop": "-5px" }}>
                    <div style={{ "float": "right", "textAlign": "right" }}>
                        <Typography.Paragraph style={{ "fontSize": "11px" }}>
                            <span style={{ "fontSize": "11px", "display": "block" }}><strong>Created: </strong>{bmDate(created * 1000)}</span>
                        </Typography.Paragraph>
                    </div>
                    <Typography.Paragraph style={{ "fontSize": "11px" }}>
                        <conditional.true value={(name)}>
                            <span style={{ "color": "#069", "fontSize": "11px", "display": "block" }}><strong>{name}</strong></span>
                        </conditional.true>
                        <conditional.true value={(email)}>
                            <div style={{ "marginTop": "-2px" }}><a href={"mailto:" + email}>{email}</a></div>
                            <div className="shim"></div>
                        </conditional.true>
                        <conditional.true value={(address_1 || address_2)}>
                            {address_1} {address_2} <br />
                        </conditional.true>
                        <conditional.true value={(city)}>
                            {city}, {province} {country} <br />
                        </conditional.true>
                        <conditional.true value={(phone || phone_2)}>
                            <conditional.true value={(phone && phone_2)}>
                                <conditional.true value={(phone)}><strong>{phone}</strong></conditional.true> -
                                <conditional.true value={(phone_2)}><strong> {phone_2}</strong></conditional.true>
                            </conditional.true>
                            <conditional.true value={!(phone && phone_2)}>
                                <conditional.true value={(phone)}><strong>{phone}</strong></conditional.true>
                                <conditional.true value={(phone_2)}><strong> {phone_2}</strong></conditional.true>
                            </conditional.true>
                        </conditional.true>
                    </Typography.Paragraph>
                </div>
            </>
        )
    }

    const drawChildTable = (_record) => {
        return (<div className="hideHover" style={{ "marginLeft": "-8px", "marginRight": "-3px", "marginTop": "-4px", "marginBottom": "-4px" }}><PurchaseActivity close={setExpanded} supplier={supplier} purchase_order={_record.purchase_order} /></div>);
    }

    const expandCell = (_data, _type) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    return (
        <div style={{ "padding": "20px", "margin": "0px" }}>
            <Typography.Title level={5}>Supplier</Typography.Title>
            <Divider style={{ "margin": "15px 0px" }} />
            <>
                {drawContact(results.supplier_info)}
                <div className="shim" /><div className="shim" />
                {drawFilters()}
                <div className="shim" /><div className="shim" />
            </>
            <div className="bc" style={{ "border": "1px solid" }}>
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
                <Table
                    loading={loading}
                    size="small"
                    columns={columns}
                    className={"tableNoBorder"}
                    dataSource={results.rows}
                    pagination={false}
                    expandedRowKeys={expanded}
                    rowClassName={(record, index) => {
                        return "sales-" + record.status;
                    }}
                    expandIconColumnIndex={-1}
                    onExpand={(e) => { return false; }}
                    expandIcon={() => { return (<></>) }}
                    expandedRowRender={((record) => drawChildTable(record))}
                />
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
            </div>
        </div>
    )
}